<template>
	<v-card>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-card-title class="title">
			<v-row no-gutters class="mb-n6">
				<v-col class="px-1">
					<v-autocomplete
						:items="filiaisFiltro"
						clearable
						item-text="filial"
						item-value="idfilial"
						label="Filial"
						outlined
						dense
						:disabled="carregando"
						v-model="busca.idfilial"
						@change="listarUsuarios()"
					></v-autocomplete>
				</v-col>
				<v-col cols="auto" class="ml-2">
					<v-btn
						:disabled="carregando"
						class="mt-1"
						color="primary"
						elevation="0"
						fab
						x-small
						@click="listar(0)"
					>
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-divider />
		<v-card-text>
			<v-alert text color="primary" icon="mdi-information" class="ma-0 pt-5 pb-0">
				<p style="font-size: 0.8em">
					<strong>Esse recurso tem o objetivo de liberar uma alçada de limite de crédito para os usuários.</strong>
<!--					<br />* Após a inclusão, serão liberados os seguintes processos para usuário (6, 7, 53, 102).-->
<!--					<br />* Após a exclusão, serão bloqueados os seguintes processos para usuário (6, 7, 53, 102).-->
				</p>
			</v-alert>
		</v-card-text>
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-divider />
		<v-card-text>
			<v-card flat dark color="primary" class="mb-3">
				<v-card-text>
					<v-row>
						<v-col class="mb-n6 px-1">
							<v-autocomplete
								v-model="cadastro.idusuario"
								outlined
								dense
								label="Novo usuário"
								:items="usuarios.lista"
								:disabled="carregando"
								item-text="usuario"
								item-value="erpidusuario"
							></v-autocomplete>
						</v-col>
						<v-col class="mb-n6 px-1">
							<v-text-field
								:disabled="carregando"
								outlined
								dense
								label="Limite"
								type="number"
								v-model.number="cadastro.limite"
							/>
						</v-col>
						<v-col cols="auto" class="pl-2">
							<v-btn fab light small @click="inserirUsuario()">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
			<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
			<v-simple-table v-else>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">Filial</th>
							<th class="text-left">Usuário</th>
							<th class="text-left">Grupo</th>
							<th class="text-left">Limite</th>
							<th class="text-left">Data de cadastro</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<template v-if="dados.total > 0">
							<tr v-for="(u, i) in dados.lista" :key="i">
								<td class="text-uppercase">{{u.idfilial}} - {{u.filial}}</td>
								<td class="text-uppercase">{{u.idusuario}} - {{u.usuario}}</td>
								<td class="text-uppercase">{{u.grupo}}</td>
								<td>{{u.limite | formataDinheiro}}</td>
								<td>{{u.dtcadastro}}</td>
								<td class="text-center">
									<v-btn fab x-small color="error" elevation="0" @click="excluirUsuario(u)">
										<v-icon>mdi-minus</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="5">Nenhum registro encontrado.</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<Paginacao
				:total="dados.total"
				@atualizar="listar"
				:carregando="carregando"
				:pg="pgSelecionada"
			/>
		</v-card-text>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>


<script>
import axios from "axios";
import { mapState } from "vuex";
import CardDialog from "../Widgets/CardDialog";
import Paginacao from "../Widgets/Paginacao";
import mixinFilial from "../../util/mixinFilial";

export default {
	name: "AnaliseLiberacaoLimite",
	components: { CardDialog, Paginacao },
	mixins: [mixinFilial],
	data: () => ({
		step: 1,
		sheet: false,
		carregando: false,
		carregandoSkeleton: true,
		dados: { total: 0 },
		cadastro: {},
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		busca: {
			idfilial: null,
		},
		usuarios: {},
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listarUsuarios() {
			this.usuarios.lista = [];
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}usuario/listar`, {
					limit: 99999,
					offset: 0,
					notidgrupo: 3,
					idfilial: this.busca.idfilial || null,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data.lista != null) {
						this.usuarios = res.data;
						this.usuarios.lista = this.usuarios.lista.filter(
							(v) => v.ativo == "F"
						);
						this.usuarios.lista = this.usuarios.lista.map((v) => {
							return {
								idusuario: v.idusuario,
								usuario: `${v.erpidusuario} - ${v.usuario}`,
								erpidusuario: v.erpidusuario,
							};
						});
					}
				});
		},
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}analise/liberacao/listar`, {
					idfilial: parseInt(this.busca.idfilial) || 0,
					limit: this.pgLimit,
					offset: this.pgLimit * pg,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		inserirUsuario() {
			if (!this.cadastro.idusuario || !this.cadastro.limite) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg = "Um ou mais campos não foram preenchidos";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}analise/inserir`, {
					idusuario: parseInt(this.cadastro.idusuario),
					limite: parseFloat(this.cadastro.limite),
				})
				.then((res) => {
					this.carregando = false;
					if (res.data) {
						this.listar(0);
						this.cadastro.idusuario = null;
						this.cadastro.limite = null;
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg = "Usuário adicionado com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg = res.data.erro;
						} else {
							this.dialogErro.msg =
								"Não foi possível inserir o usuário";
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg =
							"Não foi possível inserir o usuário";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		excluirUsuario(usuario) {
			if (!usuario.idusuario) {
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}analise/excluir`, {
					idusuario: usuario.idusuario,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data) {
						this.listar(0);
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg = "Usuário excluído com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg = res.data.erro;
						} else {
							this.dialogErro.msg =
								"Não foi possível excluir o usuário";
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg =
							"Não foi possível excluir o usuário";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		async init() {
			this.listarUsuarios();
			this.listar(0);
		},
	},
	created() {
		this.init();
	},
};
</script>